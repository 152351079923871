/**
 These overrides are applied because angular component renders component selector which
 hinders with the bootstrap CSS.
 */
@import "icons";
@import "marquee";

.input-group:not(.has-validation) {
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), app-select {
    ng-select {
      height: 100%;

      //padding-top: 0.1rem;
      //padding-bottom: 0.1rem;

      .ng-select-container {
        font-size: 0.875rem;
        //border: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 100%;
      }
    }
  }
}


.btn-group {
  > app-button:not(:last-child):not(.dropdown-toggle) {
    .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > app-button:not(:first-child) {
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.btn {
  &:not(.btn-block):not(.w-) {
    width: fit-content;
  }

  i {
    margin-right: 0.4rem;
  }
}

.input-group {
  > app-input {
    border-left: $white !important;

    .btn {
      border-left: $white !important;
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

/* Toast-UI Editor */
.toastui-editor-defaultUI {
  border-radius: $input-border-radius;

  .toastui-editor-defaultUI-toolbar {
    border-radius: $input-border-radius $input-border-radius 0 0;
    background-color: unset;

    //button {
    //  width: 24px;
    //  height: 24px;
    //}
  }

  .toastui-editor-mode-switch {
    border-radius: 0 0 $input-border-radius $input-border-radius;
  }

  .toastui-editor-md-tab-container {
    background-color: unset;
  }
}

ng-dropdown-panel {
  font-size: 0.875rem;
}

.datatable-footer-inner {
  .pagination {
    margin-bottom: 0;
  }
}

.ngx-datatable.bootstrap {
  .datatable-footer {
    background-color: unset;
    color: unset;
  }

  .datatable-body {
    .datatable-body-row {
      &.active {
        color: unset;
        background-color: unset;
      }

      &.datatable-row-even {
        background-color: unset;
      }
    }
  }
}

.file-upload-table {
  .datatable-body {
    height: 18rem !important;
  }
}

.dropdown-toggle {
  &.no-arrow {
    &::after {
      display: none;
    }
  }
}

.hr-text {
  overflow: hidden;
  display: flex;
  align-items: center;

  &:after {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 1px solid $light;
  }

  &.primary {
    &:after {
      border-color: $primary;
    }
  }
}

ngb-rating {
  font-size: 1.6rem;
  //color: white;
}

.quiz-qr {
  .qr-image {
    width: 70%;
  }
}

.live-user {
  &.live-user-sm {
    .card-body {
      img {
        width: 100%;
        border-radius: 1rem;
      }

      video {
        width: 100%;
        border-radius: 1rem;
      }
    }
  }
}

.xng-breadcrumb-root {
  .xng-breadcrumb-separator {
    color: $white;
  }

  .xng-breadcrumb-list {
    display: block;
  }

  .xng-breadcrumb-list li {
    display: inline-block;
  }

  .xng-breadcrumb-list li:last-child {
    display: block;
  }

  .xng-breadcrumb-link {
    color: $white !important;
    opacity: 0.5;
  }

  .xng-breadcrumb-trail {
    color: $white;
    font-size: 1rem;
    margin-bottom: 0;
  }
}
