/*hide overflow*/
.marquee {
  white-space: nowrap;
  overflow: hidden;

  /*the css that you need to do the transition effect*/
  span {
    display: inline-block;
    width: 100%;
    padding-top: 1.5%;

    span {
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      left: 0;
    }
  }

  &:active, &:hover {
    span {
      width: auto;

      span {
        transition: left 4s linear;
        left: calc(27%);
      }
    }
  }
}

//.marquee span {
//
//}
//.marquee span span {
//
//}
//.marquee:active span,
//.marquee:hover span {
//  width: auto;
//}
//.marquee:active span span,
//.marquee:hover span span {
//  transition: left 4s linear;
//  left: calc(65% - 15px - 100%);
//}
