/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import "@ng-select/ng-select/themes/default.theme.css";
@import "assets/scss/argon/argon-dashboard";
@import '@toast-ui/editor/dist/toastui-editor.css';
// Editor's Style
// regular style toast
@import 'ngx-toastr/toastr';
//@import "material-symbols/outlined";
@import "@flaticon/flaticon-uicons/css/regular/rounded";
@import "@flaticon/flaticon-uicons/css/brands/all";

// Custom override
@import "assets/scss/custom_overrides";

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
.ng-dropdown-panel {
  z-index: 9999 !important;
}
