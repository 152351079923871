/*!

=========================================================
* TapBeep Dashboard
=========================================================

* Product Page: https://app.tapbeep.com
* Copyright 2024 TapBeep (https://tapbeep.com)
* Licensed under MIT (site.license)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// {{ site.product.name }} components

// Variables
@import "variables/navbar-vertical";
@import "variables/social-buttons";
@import "variables/breadcrumb";

// Mixin
@import "mixins/mixins";

// Core Components - extra styling
@import "alert";
@import "accordion";
@import "avatars";
@import "badge";
@import "buttons";
@import "breadcrumbs";
@import "cards";
@import "dropdown";
@import "dropup";
@import "header";
@import "fixed-plugin";
@import "forms/forms";
@import "footer";
@import "gradients";
@import "info-areas";
@import "misc";
@import "navbar";
@import "navbar-vertical";
@import "nav";
@import "pagination";
@import "popovers";
@import "progress";
@import "rtl";
@import "social-buttons";
@import "tables";
@import "timeline";
@import "tilt";
@import "tooltips";
@import "typography";

// Dark Mode
@import "dark-version";

// Plugins
@import "plugins/free/plugins";

// Custom
@import "currency";
