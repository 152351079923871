.answer-preview {
  .form-check-input:disabled {
    opacity: 0.8 !important;
  }

  .match-highlight {
    background: $dark;
    color: $success;
    font-weight: bold;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
}

.cursor-zoom-out {
  cursor: zoom-out;
}
.cursor-zoom-in {
  cursor: zoom-in;
}

.badge-dot {
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  font-weight: 400;
  font-size: .875rem;
  text-transform: none;

  i {
    display: inline-block;
    vertical-align: middle;
    width: .375rem;
    height: .375rem;
    border-radius: 50%;
    margin-right: .375rem;
  }
}

.w-fit {
  width: fit-content;
}

.item-list {
  li {
    .view-item-alt {
      opacity: 0;
    }

    &:hover {
      .view-item-alt {
        opacity: 1;
      }
    }
  }
}

.card-auth {
  .otp {
    input {
      letter-spacing: 1rem !important;
      text-align: center;
    }
  }
}
