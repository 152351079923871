/*!
FullCalendar v6.1.8
Docs & License: https://fullcalendar.io/
(c) 2020 Adam Shaw
*/
.fc-scrollgrid, .fc-theme-standard .fc-scrollgrid {
  border: none
}

.fc-theme-standard td, .fc-theme-standard th {
  border-color: #e9ecef
}

.fc-theme-standard thead tr th {
  border-right: none;
  border-left: none
}

.fc-theme-standard td:last-child {
  border-right: none
}

.fc-theme-standard tr:last-child td {
  border-bottom: none
}

.fc-h-event {
  border: none
}

.fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
  font-weight: 600;
  padding: .2rem .3rem
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0
}

.fc .fc-col-header-cell-cushion {
  font-size: .875rem;
  font-weight: 600;
  color: #adb5bd
}

.fc .fc-daygrid-day-number {
  color: #495057;
  font-size: .875rem;
  font-weight: 600;
  width: 100%;
  text-align: center
}

.fc .fc-toolbar-title {
  font-size: 1.125rem
}

.fc .fc-button {
  padding-top: .25rem;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .1), 0 1px 3px rgba(0, 0, 0, .08);
  transition: all .15s ease-in
}

.fc .fc-button, .fc .fc-button .fc-icon {
  font-size: .875rem
}

.fc .fc-button-primary {
  background-color: #8392ab;
  border-color: #8392ab
}

.fc .fc-button-primary:focus, .fc .fc-button-primary:hover, .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled):active:focus {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
  background-color: #8392ab;
  border-color: #8392ab
}

.fc-event.fc-daygrid-event {
  border-radius: .35rem
}
