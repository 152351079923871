.accordion {
  div {
    button {
      &[aria-expanded=true] {
        .collapse-open {
          display: block;
        }
        .collapse-close {
          display: none;
        }
      }
      &[aria-expanded=false] {
        .collapse-open {
          display: none;
        }
        .collapse-close {
          display: block;
        }
      }
    }
  }
}
